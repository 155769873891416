/* eslint-disable complexity */
import React from 'react';
import { graphql, navigate } from 'gatsby';
import { css } from '@emotion/core';
import FullWidthBox from 'components/FullWidthBox';
import { isLoggedIn } from 'utils/auth';
import { Row, Col, Typography } from 'antd';
import { TeamOutlined, UserOutlined } from '@ant-design/icons';
import { AppStateContext } from 'state/appstate';

const Intro = () => {
  const { Title } = Typography;
  const { appValue, getAppColors } = React.useContext(AppStateContext);

  const appColor = getAppColors();
  const showLoginOrNavigate = () => {
    if (isLoggedIn()) {
      navigate('/app/home/');
    } else {
      navigate('/app/login/');
    }
  };
  return (
    <FullWidthBox
      css={css`
        height: 100vh;
        padding-top: 126px;
      `}
    >
      <article
        css={css`
          height: 100%;
        `}
      >
        <Row
          gutter={16}
          css={css`
            // margin-bottom: 100px;
            height: 100%;
          `}
        >
          <Col
            onClick={showLoginOrNavigate}
            css={css`
              display: flex;
              align-items: center;
              justify-content: center;
              flex-direction: column;
              cursor: pointer;
            `}
            span={6}
            xs={24}
            sm={6}
          >
            <Title
              css={css`
                color: #1d1d1b !important;
                // text-transform: uppercase;
              `}
              level={2}
            >
              {(appValue === 'nachbarschaft' || appValue === undefined) && (
                <>Hilfe suchen</>
              )}
              {appValue === 'work' && <>Projekte suchen</>}
              {appValue === 'seelsorge' && <>Hilfe suchen</>}
            </Title>
            <p
              css={css`
                color: #1d1d1b;
              `}
            >
              {(appValue === 'nachbarschaft' || appValue === undefined) && (
                <>
                  Alle, die im Alltag Unterstützung brauchen, bspw. beim
                  Hundeausführen, die ihre Einkäufe nicht selbst erledigen
                  können oder Nachhilfeunterricht benötigen, können über die
                  angebotenen Rubriken ihr Gesuch lokalbasiert aufgeben. bleibt
                  gesund!
                </>
              )}
              {appValue === 'work' && (
                <>
                  Ihr wollt ein Projekt umsetzen, wisst aber nicht, wie ihr das
                  in diesen Zeiten gut bewerkstelligen könnt, da ihr nicht
                  reisen könnt? Stellt eure Projekte ins Netz und findet Leute
                  vor Ort, die diese Arbeit für euch erledigen können.
                </>
              )}
              {/* {appValue === 'seelsorge' && <>Hilfe suchen</>} */}
            </p>
          </Col>
          <Col
            className="gutter-row"
            span={12}
            xs={24}
            sm={12}
            onClick={showLoginOrNavigate}
            css={css`
              display: flex;
              cursor: pointer;
              height: 100%;
              align-items: center;
              justify-content: center;
              position: relative;
              margin-top: 30px;
              .grow {
                transition: all 0.2s ease-in-out;
              }
              .grow:hover {
                transform: scale(1.05);
              }
              .helpOffer {
                width: 80%;
                background: ${appColor};
                height: calc(80vw / 2 - 8px);
                border-radius: 80%;
                -moz-border-radius: 80%;
                -webkit-border-radius: 80%h;
                -o-border-radius: 80%;
                @media (max-width: 576px) {
                  height: calc(140vw / 2 - 8px);
                }
              }
              .helpSearch {
                display: flex;
                width: 20%;
                height: calc(100vw / 10 - 8px);
                background: #ffffff;
                border-radius: 20%;
                -moz-border-radius: 120%;
                -webkit-border-radius: 50%;
                -o-border-radius: 20%;
                @media (max-width: 576px) {
                  height: calc(100vw / 5 - 8px);
                }
              }
            `}
          >
            <div className="helpOffer grow">
              <TeamOutlined
                css={css`
                   {
                    display: flex;
                    justify-content: center;
                    margin-top: 30px;
                    font-size: 2em;
                    color: #ffffff;
                  }
                `}
              />
            </div>
            <div
              css={css`
                width: 100%;
                left: 0;
                right: 0;
                display: flex;
                position: absolute;
                justify-content: center;
              `}
            >
              <div className="helpSearch grow">
                <UserOutlined
                  css={css`
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 100%;
                    font-size: 2em;
                  `}
                />
              </div>
            </div>
          </Col>
          <Col
            onClick={showLoginOrNavigate}
            css={css`
              display: flex;
              align-items: center;
              justify-content: center;
              flex-direction: column;
              cursor: pointer;
            `}
            span={6}
            xs={24}
            sm={6}
          >
            <Title
              css={css`
                color: #1d1d1b !important;
              `}
              level={2}
            >
              {(appValue === 'nachbarschaft' || appValue === undefined) && (
                <>Hilfe anbieten</>
              )}
              {appValue === 'work' && <>Projekte anbieten</>}
              {appValue === 'seelsorge' && <>Hilfe suchen</>}
            </Title>

            {/* <ul
                css={css`
                  li {
                    list-style: none;
                    list-style-type: none !important;
                    padding: 0 !important;
                    margin: 0 !important;
                  }
                `}
              >
                <li>
                  <ExternalLink traget="_blank" href="www.rki.de">
                    www.rki.de
                  </ExternalLink>
                </li>
                <li>
                  <ExternalLink traget="_blank" href="www.bundesregierung.de">
                    www.bundesregierung.de
                  </ExternalLink>
                </li> */}
            {/* <li>
                  <ExternalLink traget="_blank" href="www.loremipsum.de">
                    www.loremipsum.de
                  </ExternalLink>
                </li>
                <li>
                  <ExternalLink traget="_blank" href="www.loremipsum.de">
                    www.loremipsum.de
                  </ExternalLink>
                </li>
                <li>
                  <ExternalLink traget="_blank" href="www.loremipsum.de">
                    www.loremipsum.de
                  </ExternalLink>
                </li>
                <li>
                  <ExternalLink traget="_blank" href="www.loremipsum.de">
                    www.loremipsum.de
                  </ExternalLink>
                </li> */}
            {/* </ul> */}
            {(appValue === 'nachbarschaft' || appValue === undefined) && (
              <>
                <p>
                  Vielleicht trifft auch euch die Krise hart und ihr habt auf
                  einmal Zeit, da ihr nicht zur Arbeit gehen könnt. Hier könnt
                  ihr diese sinnvoll nutzen, in dem ihr andere Menschen, die nur
                  noch sehr eingeschränkt agieren können, mit eurer
                  Unterstützung den Alltag leichter macht bzw. überhaupt
                  ermöglichen.
                </p>
                <p>
                  Neben alltäglichen Erledigungen möchten wir auch
                  Kreativschaffenden die Möglichkeit geben, Projekte anzutreiben
                  und umzusetzen. Natürlich im Rahmen der geltenden Auflagen –
                  und mit lokalen Experten vor Ort, die Anreisen unnötig machen.
                </p>
              </>
            )}
            {appValue === 'work' && (
              <p>
                Jeder Freelancer und Kreative, der aktuell durch eingefrorene
                Budgets oder Produktionsstops über freie Kapazitäten verfügt,
                kann seine Skills und Erfahrungen auf der Plattform teilen.
              </p>
            )}
            {/* {appValue === 'seelsorge' && <>Hilfe suchen</>} */}
          </Col>
        </Row>
      </article>
    </FullWidthBox>
  );
};
export const query = graphql`
  query Intro {
    site: site {
      siteMetadata {
        organization {
          email
          name
          address {
            streetNumber
            zipCity
          }
        }
        social {
          googlemaps
        }
      }
    }
  }
`;

export default Intro;
